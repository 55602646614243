import { Link, Text } from '@chakra-ui/react';

import React from 'react';

const hpFaq = [
	{
		question: 'Who can order from DoctorMedica?',
		answer: (
			<>
				<Text>
					To order medical supplies from DoctorMedica, you must be licensed to
					handle the products you order. Feel free to{' '}
					<Link
						textDecoration={'underline'}
						href='https://www.doctormedica.co/contact-us'>
						contact us
					</Link>{' '}
					if you're unsure about the exact licensing requirements.
				</Text>
			</>
		),
	},
	{
		question: 'Why should I order from DoctorMedica?',
		answer: (
			<>
				<Text>
					Here, you'll find only the most trusted brands at prices designed to
					empower your decisions. Our{' '}
					<Link
						textDecoration={'underline'}
						href='https://www.doctormedica.co/how-to-order'>
						ordering process
					</Link>{' '}
					is streamlined for ease and backed by a dedicated sales representative
					and customer service excellence.
				</Text>
			</>
		),
	},
	{
		question: 'What if I find a cheaper price on medical supplies elsewhere?',
		answer:
			'Doctor Medica strives to bring you the lowest possible prices on medical injectables and other supplies. If you find a lower price elsewhere, please let us know. If you provide proof of the lower price and the terms are similar to ours, we will do our best to match or beat it.',
	},
	{
		question: 'How much does shipping cost from DoctorMedica?',
		answer:
			'Shipping is complimentary for orders above $500. However, if your order is less than $500, a shipping fee of $50 will apply.',
	},
	{
		question: 'How do discounts work on DoctorMedica?',
		answer: (
			<>
				<Text>
					When ordering wholesale medical supplies from DoctorMedica, discounts
					can be obtained by purchasing larger quantities. The more you buy, the
					lower the price. Besides discounts per product quantity, we also offer
					regular{' '}
					<Link
						textDecoration={'underline'}
						href='https://www.doctormedica.co/promotions'>
						promotional discounts
					</Link>
					.
				</Text>
			</>
		),
	},
	{
		question: 'What are the medical licenses that Doctor Medica recognizes?',
		answer: (
			<>
				<Text>The acceptable/valid medical licenses are:</Text>
				<ul>
					<li>Medical Doctor</li>
					<li>Osteopathic Doctor</li>
					<li>Doctor of Medical Dentistry</li>
					<li>Doctor of Dental Surgery</li>
					<li>Doctor of Podiatric Medicine</li>
					<li>Physician Assistant</li>
					<li>Nurse Practitioner</li>
					<li>Advanced Registered Nurse Practitioner</li>
					<li>Advanced Practice Registered Nurse</li>
					<li>Family Nurse Practitioner</li>
					<li>Advanced Practice Nurse</li>
					<li>Doctor of Veterinary Medicine</li>
				</ul>
			</>
		),
	},
	{
		question: 'Are all products sold by your company guaranteed to be genuine?',
		answer: (
			<>
				<Text>
					Doctor Medica exclusively offers authentic products with original LOT
					numbers. Feel free to{' '}
					<Link
						textDecoration={'underline'}
						href='/contact-us'>
						contact us
					</Link>{' '}
					if you need the LOT number confirmation.
				</Text>
			</>
		),
	},
];

export default hpFaq;
