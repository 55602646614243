import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import React from "react";

function PromoBanner() {
  return (
    <>
      <Link zIndex={2} href="/promotions" _hover={{ color: "gray.900" }}>
        <Box
          // position='absolute'
          // top={{ base: '714px', sm: '614px' }}
          // left='50%'
          // transform='translate(-50%, -50%)'
          p={{ base: "40px 20px", sm: "40px" }}
          minW={{ base: "100%", sm: "320px" }}
          w="320px"
          h="400px"
          borderRadius="12px"
          boxShadow="0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);"
          bgImage="/banner.webp"
          bgPosition="center"
          bgSize="cover"
          bgRepeat="no-repeat"
        ></Box>
      </Link>
    </>
  );
}

export default PromoBanner;
