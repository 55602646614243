import { gql } from "@apollo/client";

export const fetchTopSales = () => {
  const query = gql`
    {
      products(
        where: {
          orderby: { field: TOTAL_SALES, order: DESC }
          dateQuery: { column: DATE }
        }
        first: 16
      ) {
        nodes {
          name
          id
          databaseId
          slug
          image {
            sourceUrl
            altText
          }
        }
      }
    }
  `;

  return query;
};
