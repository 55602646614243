import { graphql, useStaticQuery } from 'gatsby';

export const newInStock = () => {
	const data = useStaticQuery(graphql`
		query newInStock(
			$productsId: [Int] = [
				50170
				50159
				55523
				55529
				52356
				49845
				52373
				52357
				49260
				48668
			]
		) {
			allWpSimpleProduct(filter: { databaseId: { in: $productsId } }) {
				nodes {
					name
					id
					slug
					databaseId
					image {
						sourceUrl
						altText
					}

					promotions {
						onPromotion
					}
					productTags {
						nodes {
							name
							id
							slug
						}
					}
					productCategories {
						cat: nodes {
							name
							id
							slug
						}
					}

					... on WpSimpleProduct {
						id
						name
						price
						regularPrice
						onSale
						salePrice
					}
				}
			}
		}
	`);

	const simpleProducts = data.allWpSimpleProduct.nodes;

	return simpleProducts;
};
