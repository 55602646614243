// extracted by mini-css-extract-plugin
export var angelA = "review-card-module--angelA--2e635";
export var angelAParent = "review-card-module--angelAParent--c4426";
export var bestSupplier = "review-card-module--bestSupplier--cc0de";
export var content = "review-card-module--content--f9c49";
export var iveBeenIn = "review-card-module--iveBeenIn--d8619";
export var northAmerica = "review-card-module--northAmerica--55b7b";
export var p = "review-card-module--p--35430";
export var profile = "review-card-module--profile--e4ee5";
export var rating = "review-card-module--rating--d0a2d";
export var rating1 = "review-card-module--rating1--ff5fc";
export var star1Icon = "review-card-module--star1Icon--7a323";
export var stars = "review-card-module--stars--d32bc";