import * as React from 'react';
import { FunctionComponent } from 'react';
import * as styles from './review-card.module.css';
import { Text } from '@chakra-ui/react';

type ReviewCardType = {
	starIcon1?: string;
	starIcon2?: string;
	starIcon3?: string;
	ratingTitle?: string;
	ratingText?: string;
	ratingName?: string;
};

const ReviewCard: FunctionComponent<ReviewCardType> = ({
	starIcon1,
	starIcon2,
	starIcon3,
	ratingTitle,
	ratingText,
	ratingName,
}) => {
	return (
		<div className={styles.rating}>
			<div className={styles.content}>
				<div className={styles.rating1}>
					<div className={styles.stars}>
						<img
							className={styles.star1Icon}
							alt=''
							src='/star-1.svg'
						/>
						<img
							className={styles.star1Icon}
							alt=''
							src='/star-2.svg'
						/>
						<img
							className={styles.star1Icon}
							alt=''
							src={starIcon1}
						/>
						<img
							className={styles.star1Icon}
							alt=''
							src={starIcon2}
						/>
						<img
							className={styles.star1Icon}
							alt=''
							src={starIcon3}
						/>
					</div>
					<Text
						as='p'
						fontWeight={700}
						className={styles.bestSupplier}>
						{ratingTitle}
					</Text>
				</div>
				<div className={styles.p}>
					<Text
						as='p'
						className={styles.iveBeenIn}>
						{ratingText}
					</Text>
				</div>
				<div className={styles.profile}>
					<div className={styles.angelAParent}>
						<Text
							as='p'
							className={styles.angelA}>
							{ratingName}
						</Text>
						<Text
							as='span'
							className={styles.northAmerica}>
							North America
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewCard;
