import { Heading, Text } from '@chakra-ui/react';

import React from 'react';

const promotionCards = [
	{
		title: (
			<Text
				as='h2'
				color='#120B0C'
				fontSize='2xl !important'
				fontWeight={400}
				lineHeight='150%'>
				Get Up to <span style={{ color: '#D28C49' }}>3% Cashback</span>
			</Text>
		),
		description:
			"Join Doctor Medica's Loyalty Program. Benefit from up to 3% cashback on every purchase, enjoy discounts of up to 9%, receive personalized gifts, and beyond.",
		buttonText: 'LEARN MORE',
		link: 'https://www.doctormedica.co/loyalty-program',
	},
	{
		title: (
			<Text
				as='h2'
				color='#120B0C'
				fontSize='2xl !important'
				fontWeight={400}
				lineHeight='150%'>
				Earn With Our <br />
				<span style={{ color: '#D28C49' }}>Referral Program</span>
			</Text>
		),
		description:
			'Enjoy a $350* credit on your account for every successful referral. Plus, earn an extra $50 for every fifth referral. Colleagues you refer will also get a credit.<br> ',
		buttonText: 'REFER NOW',
		link: 'https://www.doctormedica.co/referral-program',
	},
	{
		title: (
			<Text	
				as='h2'
				color='#120B0C'
				fontSize='2xl !important'
				fontWeight={400}
				lineHeight='150%'>
				Explore <span style={{ color: '#D28C49' }}>Price Match</span> Program
			</Text>
		),
		description:
			'If you find an identical medical injectable for a lower price from a different company, we will do our best to meat or beat the price.',
		buttonText: 'Get in touch with us',
		link: 'https://www.doctormedica.co/contact-us',
	},
];

export default promotionCards;
