// extracted by mini-css-extract-plugin
export var animate = "index-module--animate--a87d2";
export var backdropIcon = "index-module--backdropIcon--f2f62";
export var categories = "index-module--categories--27456";
export var categorycard = "index-module--categorycard--a5175";
export var content = "index-module--content--f97f8";
export var darkButton = "index-module--darkButton--0dc8e";
export var divider = "index-module--divider--09051";
export var errorMsg = "index-module--errorMsg--dff35";
export var homePage = "index-module--homePage--e91f3";
export var ifYouAre = "index-module--ifYouAre--56ae3";
export var label = "index-module--label--dc0e8";
export var p = "index-module--p--ab438";
export var ratings = "index-module--ratings--4bec8";
export var ratings1 = "index-module--ratings1--43d4e";
export var ratingssection = "index-module--ratingssection--aa0dd";
export var ratingssection1 = "index-module--ratingssection1--6cb8e";
export var refferralsection = "index-module--refferralsection--cfcfa";
export var reviewSwiper = "index-module--reviewSwiper--af295";
export var reviews = "index-module--reviews--3a9d0";
export var saleproducts = "index-module--saleproducts--93d54";
export var salestextbox = "index-module--salestextbox--87c4f";
export var sectionParagraph = "index-module--sectionParagraph--4895c";
export var sectioncontent = "index-module--sectioncontent--8e319";
export var sectiontitle = "index-module--sectiontitle--da5b4";
export var shopForThe = "index-module--shopForThe--c0307";
export var skincare = "index-module--skincare--9026e";
export var swiperScrollbarDrag = "index-module--swiper-scrollbar-drag--511d3";
export var swiperWidth = "index-module--swiperWidth--1fc74";
export var title = "index-module--title--16c47";
export var title1 = "index-module--title1--486c4";
export var title2 = "index-module--title2--5e163";
export var titlebox = "index-module--titlebox--f0b86";
export var topCategories = "index-module--topCategories--6d8b5";
export var topSales = "index-module--topSales--edd56";
export var topsalessection = "index-module--topsalessection--0d93c";
export var whatOurCustomers = "index-module--whatOurCustomers--aac44";