import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';

// CardComponent.tsx
import React from 'react';

interface PromotionCardProps {
	promotionTitle: any;
	promotionText: string;
	ctaText: string;
	link: string;
}

const PromotionCard: React.FC<PromotionCardProps> = ({
	promotionTitle,
	promotionText,
	ctaText,
	link,
}) => {
	return (
		<Box w={{ base: '100%', md: '426px' }}>
			<Box
				p={8}
				borderRadius='10px'
				bg='#fff'
				w={{ base: '100%', md: '426px' }}
				h={{ base: '100%', md: '312px' }}
				minH='312px'
				display='flex'
				flexFlow='column'
				justifyContent='space-between'>
				<Box>{promotionTitle}</Box>
				<Text
					color='#262626'
					fontSize='md'
					fontWeight={400}
					dangerouslySetInnerHTML={{ __html: promotionText }}></Text>
				<Link href={link}>
					<Button
						fontSize='sm'
						fontWeight={700}
						color='#120B0C'
						variant='outline'
						textTransform='uppercase'
						w={{ base: '100%', md: 'auto' }}>
						{ctaText}
					</Button>
				</Link>
			</Box>
		</Box>
	);
};

export default PromotionCard;
