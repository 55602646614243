// extracted by mini-css-extract-plugin
export var discoverProducts = "hero-video-section-module--discoverProducts--25238";
export var headingLarge3xl = "hero-video-section-module--headingLarge3xl--64cc2";
export var heroContent = "hero-video-section-module--heroContent--d783d";
export var heroText = "hero-video-section-module--heroText--53b27";
export var herobackground = "hero-video-section-module--herobackground--88921";
export var herosection = "hero-video-section-module--herosection--3e288";
export var lightButton = "hero-video-section-module--lightButton--1915d";
export var overlay = "hero-video-section-module--overlay--a8b8c";
export var remixIconslinesystemarrow = "hero-video-section-module--remixIconslinesystemarrow--3f968";
export var sectionText = "hero-video-section-module--sectionText--271a6";
export var sectionicon = "hero-video-section-module--sectionicon--855b9";